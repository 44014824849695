/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
import { Breakpoint, Theme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { useSubMenuContext } from 'context/SubMenuContext';
import useDynamicTheme from 'hooks/useDynamicTheme';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type centeredUntilType = Breakpoint | number | undefined;

interface Props {
  tablist: Record<string, string>;
  centeredUntil?: centeredUntilType;
}

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const getListItems = (categories: Record<string, string>) => {
  const listItems = [];
  for (const key in categories) {
    if (categories.hasOwnProperty(key)) {
      listItems.push({
        label: key,
        link: categories[key],
      });
    }
  }

  return listItems;
};

/* 
  Apparently, the tabs documentation on colors messed up.
  maybe a universal text system later?
*/
const useStyles = makeStyles((t: Theme) => ({
  root: {
    backgroundColor: t.palette.primary.main,
    color: `${t.palette.surface.main}${t.palette.other.opacityHigh}`,
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.2), 0px 4px 4px rgba(0, 0, 0, 0.1)',
  },
  indicator: {
    backgroundColor: `${t.palette.surface.main}${t.palette.other.opacityHigh}`,
  },
  textColor: {
    color: `${t.palette.surface.main}${t.palette.other.opacityHigh} !important`,
  },
  textColorDisabled: {
    color: `${t.palette.surface.main}${t.palette.other.opacityDisabled}`,
  },
  manyTabs: {
    minWidth: '90px',
    maxWidth: '360px',
  },
}));

/**
 * Checks if it meets up to the breakpoint and "centers" the tabbar until it is fullfilled
 *
 * @param centeredUntil until which breakpoint it should be centered.
 * @param tablistLength length of the tab size
 * @returns true if it's met, false if it's not met
 */
const centeredBreakpoint = (theme: Theme, centeredUntil: centeredUntilType, tablistLength: number) => {
  if (centeredUntil) {
    return useMediaQuery(theme.breakpoints.down(centeredUntil)) && tablistLength > 3;
  }
  return tablistLength > 3;
};

const TabBar: React.FC<Props> = ({ tablist, centeredUntil }) => {
  const theme = useDynamicTheme();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const tabs = getListItems(tablist);
  const subMenu = useSubMenuContext();

  // Sets active tab based on the url.
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!tabs) return;
    tabs.forEach((tab, index) => {
      if (location?.pathname.includes(tab?.link)) {
        setValue(index);
      }
    });
  }, [location, location?.pathname, tabs]);

  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setValue(newValue);
  };

  const generateClassName = (index: number) => {
    let str = value === index ? classes.textColor : classes.textColorDisabled;
    if (tabs.length > 3) {
      str += ` ${classes.manyTabs}`;
    }
    return str;
  };

  const listItems = tabs.map((item, index) => (
    <Tab
      key={item.label}
      label={item.label}
      onClick={() => navigate(item.link)}
      className={generateClassName(index)}
      {...a11yProps(index)}
    />
  ));

  if (subMenu.isSubMenu) {
    return <></>;
  }

  /*
    this was the most effective way I found out to change the text color depending
    on if it is active or not.
  */
  return (
    <Hidden mdUp>
      <AppBar position="sticky" elevation={0}>
        <Tabs
          centered={!centeredBreakpoint(theme, centeredUntil, tabs.length)}
          classes={{ indicator: classes.indicator }}
          className={classes.root}
          variant={centeredBreakpoint(theme, centeredUntil, tabs.length) ? 'scrollable' : 'fullWidth'}
          value={value}
          scrollButtons
          onChange={handleChange}
          aria-label="simple tabs example"
          allowScrollButtonsMobile
        >
          {listItems}
        </Tabs>
      </AppBar>
    </Hidden>
  );
};

export default TabBar;
